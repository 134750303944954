import React from "react"
import { GATSBY_DOCTOR_BRIEFING_GUIDES_LINK } from "gatsby-env-variables"

const DoctorCompletedMessage = ({ type }) => {
  if (type === "doctor")
    return (
      <article className="message is-light">
        <div className="message-body">
          You may download{" "}
          <span className="has-text-weight-bold">
            Patient Educational Materials
          </span>{" "}
          <a
            href={GATSBY_DOCTOR_BRIEFING_GUIDES_LINK}
            target="blank"
            rel="noreferrer noopener"
          >
            here
          </a>
          .
        </div>
      </article>
    )

  return null
}

export default DoctorCompletedMessage
