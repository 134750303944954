import React from "react"
import { GATSBY_PATIENT_BRIEFING_GUIDES_LINK } from "gatsby-env-variables"

const PatientCompletedMessage = ({ type }) => {
  if (type === "patient")
    return (
      <article className="message is-light">
        <div className="message-body">
          You may download a guide on{" "}
          <span className="has-text-weight-bold">How to Order</span>{" "}
          <a
            href={GATSBY_PATIENT_BRIEFING_GUIDES_LINK}
            target="blank"
            rel="noreferrer noopener"
          >
            here
          </a>
          .
        </div>
      </article>
    )
  return null
}

export default PatientCompletedMessage
